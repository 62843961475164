import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import logo from '../logo.png';

class Header extends Component {
    constructor(props) {
        super(props);
        this.bodyClass = this.bodyClass.bind(this);
    }
    bodyClass = (event) => {
        event.target.text.trim() === "Contact" ?
            document.body.classList.add('Contact') : 
            document.body.classList.remove('Contact');
    }
    render(){
        return(
            <header className="Header">
                <Navbar bg="white" expand="lg" fixed="top">
                    <div className="container">
                        <Navbar.Brand href="/"><img src={logo} alt="awaiskh.com" className="logo"/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Link to='/' onClick={this.bodyClass}>Home</Link>
                            <Link to='/portfolio' onClick={this.bodyClass}>Portfolio</Link>
                            <Link to='/about' onClick={this.bodyClass}>About</Link>
                            <Link to='/contact' className="btn btn-outline-primary" onClick={this.bodyClass}>Contact</Link>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </header>
        );
    }
}

export default Header;