import React, { Component } from 'react';
import avatar from '../../images/avatar.png';
import axios from 'axios';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: ''
        }
        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.close = this.close.bind(this);
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }
    handleSubmit(event) {
        event.preventDefault();
        axios({
            method: "POST",
            url: "/server.php",
            data: this.state
        }).then((response) => {
            if (response.data.status === 'success') {
                const popupEl = document.querySelector('.js_success-popup');
                popupEl.classList.add('popup--visible');
                this.resetForm()
            } else if (response.data.status === 'fail') {
                const popupEl = document.querySelector('.js_error-popup');
                popupEl.classList.add('popup--visible');
            }
        });
    }
    close() {
        const popupEl = document.querySelectorAll('.popup');
        Array.from(popupEl).map(el => el.classList.remove('popup--visible'));
    }
    resetForm() {
        this.setState({ name: '', email: '', message: '' })
    }

    render() {
        return (
            <GoogleReCaptchaProvider reCaptchaKey="6LeLc9IUAAAAAFOrJ23p43wr4tn5XaVJDU0QJqis" language="EN">
                <section className="section-contact">
                    {/* Popup */}
                    <div className="popup popup--icon -success js_success-popup">
                        <div className="popup__background"></div>
                        <div className="popup__content">
                            <h3 className="popup__content__title">
                                Mail successfully Sent
                            </h3>
                            <button className="button button--success" onClick={this.close}>Close</button>
                        </div>
                    </div>
                    <div className="popup popup--icon -error js_error-popup">
                        <div className="popup__background"></div>
                        <div className="popup__content">
                            <h3 className="popup__content__title">
                                Opps Something Went Wrong
                            </h3>
                            <button className="button button--error" onClick={this.close}>Close</button>
                        </div>
                    </div>
                    <div className="container">
                        <div className="text-icon">
                            <img className="avatar" src={avatar} alt="" />
                            <h1 className="title text-center text-weight--900">Thanks for taking the time to reach out. How can I help you today?</h1>
                        </div>
                        <div className="form-container">
                            <form id="form" acceptCharset="UTF-8" action="" onSubmit={this.handleSubmit} method="POST">
                                <div className="email-name">
                                    <div className="field">
                                        <label className="label" htmlFor="name">Name</label>
                                        <div className="control is-expanded">
                                            <input id="name" className="input is-large" name="name" type="text" value={this.state.name} required="" onChange={this.onNameChange} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label" htmlFor="email">Email</label>
                                        <div className="control is-expanded">
                                            <input id="email" className="input is-large" name="email" type="email" value={this.state.email} required="" onChange={this.onEmailChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Message</label>
                                    <div className="control is-expanded" htmlFor="message">
                                        <textarea id="message" className="textarea is-large" name="message" rows="5" value={this.state.message} required="" onChange={this.onMessageChange} />
                                    </div>
                                </div>
                                <div className="field is-hidden">
                                    <label className="is-hidden"></label>
                                    <div className="control is-expanded is-hidden">
                                        <input className="is-hidden" name="_gotcha" type="hidden" />
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control">
                                        <button className="btn btn-outline-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </GoogleReCaptchaProvider>
        );
    }
}

export default Contact;