import React, { Component } from 'react';
import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";
import Client from '../../Contentful';

class Portfolio extends Component {

    state = {
        projects: []
    };

    componentDidMount() {
        this.getData();
    }

    loadMasonryGallery = () => {
        let grid = document.querySelector('.grid');
        let iso = new Isotope(grid, {
            // options...
            itemSelector: '.grid-item',
            //layoutMode: 'fitRows',
            masonry: {
                columnWidth: '.grid-sizer',
                gutter: 60,
                horizontalOrder: true
            }
        });
        imagesLoaded( grid, function( instance ) {
            iso.layout();
        });
    }

    getData = async () => {
        try {
            let response = await Client.getEntries({
                //content_type: 'Portfolio-Sites'
            });
            this.setState({ projects: response.items });
            this.loadMasonryGallery();
        }
        catch(error){
            console.log(error);
        }
    }

    render() {
        let projects = this.state.projects;
        return (
            <section className="section-portfolio">
                <div className="container">
                    <div className="grid">
                        <div className="grid-sizer"></div>
                        {projects.map(proj => {
                            return (
                                <div className={`grid-item ${proj.fields.siteImage.fields.file.details.image.height > 700 && 'grid-item--width2'}`} key={proj.sys.id}>
                                    <img className="avatar" src={proj.fields.siteImage.fields.file.url} alt={proj.fields.siteImage.fields.file.title} />
                                    <div className="info-layer">
                                        <h4>{proj.fields.siteName}</h4>
                                        <a href={proj.fields.siteLink}>{proj.fields.siteLink}</a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Portfolio;