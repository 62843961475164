import React from 'react';
import { Card } from 'react-bootstrap';
import design from '../../images/computer.svg';
import wesite from '../../images/website.svg';
import online from '../../images/online.svg';

const AboutUs = () => {
    return (
        <>
            <section className="section-about first">
                <div className="container">
                    <div className="column col-lg-9 col-12 mx-auto d-block">
                        <h1 className="title text-center text-weight--900 white">Hi, I’m Awais. Nice to meet you.</h1>
                        <p className="subtitle text-center white">Since beginning my journey as a frontend developer nearly 7 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use. I'm quietly confident, naturally curious, and perpetually working on improving my chops one real world problem at a time.</p>
                    </div>
                </div>
            </section>

            <section className="section-skills">
                <div className="container">
                    <Card className="box rounded-xl">
                        <div className="row">
                            <div className="col col-lg-4 col-md-6 col-12 border-right--1">
                                <figure className="image"><img className="is-skill-icon mx-auto d-block mt-5" src={design} alt="" /></figure>
                                <h3 className="title text-center text-weight--600 mt-5 mb-3">Designer</h3>
                                <p>I value simple content structure, clean design patterns, and thoughtful interactions.</p>
                                <p className="list-title mt-5">Things I enjoy designing:</p>
                                <p>UX, UI, Web, Mobile, Apps, Logos</p>
                                <p className="list-title mt-5">Design Tools:</p>
                                <ul>
                                    <li>Figma</li>
                                    <li>Invision</li>
                                    <li>Adobe XD</li>
                                    <li>Sketch</li>
                                    <li>Photoshop</li>
                                    <li>Illustrator</li>
                                </ul>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12 border-right--1">
                                <figure className="image"><img className="is-skill-icon mx-auto d-block mt-5" src={wesite} alt="" /></figure>
                                <h3 className="title text-center text-weight--600 mt-5 mb-3">Front-end Developer</h3>
                                <p>I like to code things from scratch, and enjoy bringing ideas to life in the browser.</p>
                                <p className="list-title mt-5">Languages I speak:</p>
                                <p>HTML, CSS, Sass, Javascript, React, Redux, PHP</p>
                                <p className="list-title mt-5">Dev Tools:</p>
                                <ul>
                                    <li>Atom</li>
                                    <li>Bitbucket</li>
                                    <li>Bootstrap</li>
                                    <li>Bulma</li>
                                    <li>Codepen</li>
                                    <li>Github</li>
                                    <li>Linux/Unix</li>
                                </ul>
                            </div>
                            <div className="col col-lg-4 col-12 ">
                                <figure className="image"><img className="is-skill-icon mx-auto d-block mt-5" src={online} alt="" /></figure>
                                <h3 className="title text-center text-weight--600 mt-5 mb-3">Mentor</h3>
                                <p>I genuinely care about people, and love helping fellow designers and developers work on their craft.</p>
                                <p className="list-title mt-5">Experiences I draw from:</p>
                                <p>UX/UI, Product design and development, Freelancing</p>
                                <p className="list-title mt-5">Mentor Stats:</p>
                                <ul>
                                    <li>5 years experience</li>
                                    <li>30+ students</li>
                                </ul>
                            </div>
                        </div>
                    </Card>
                </div>
            </section>
        </>
    );
}

export default AboutUs;