import React from 'react';
import Header from './templates/Header';
import Home from './templates/pages/Home';
import Porfolio from './templates/pages/Portfolio';
import AboutUs from './templates/pages/AboutUs';
import Contact from './templates/pages/Contact';
import { Route, Switch } from "react-router-dom";
import './App.scss';

function App() {
    return (
        <div className="App">
            <Header></Header>
            <Switch>
                <Route path='/' exact component={Home} ></Route>
                <Route path='/portfolio' component={Porfolio} ></Route>
                <Route path='/about' component={AboutUs} ></Route>
                <Route path='/contact' component={Contact} ></Route>
            </Switch>
        </div>
    );
}

export default App;
