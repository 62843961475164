import React from 'react';
import { Link } from 'react-router-dom';
import avatar from '../../images/Profile.jpeg';

const Home = () => {
    return (
        <section className="container home">
            <div className="top-buffer">
                <h1 className="title text-center text-weight--900">Designer, Front-end Developer &amp; Mentor</h1>
                <h2 className="subtitle text-center text-weight--300">I design and code beautifully simple things, and I love what I do.</h2>
                <div className="avatar mx-auto d-block mt-5">
                    <img className="profile" src={avatar} alt="avatar" width="250" />
                </div>
                <Link to="Portfolio" className="btn btn-outline-primary mx-auto d-block mt-5" >View my work&nbsp;
                    <svg id="i-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
                        <path d="M12 30 L24 16 12 2" />
                    </svg>
                </Link>
            </div>
        </section>
    );
}

export default Home;